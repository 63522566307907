export default {
    // 中文配置
    email_address: '邮箱地址',
    unleashing: '让',
    creativity: '创意',
    unbounded: '释放无边无际',
    platforms: '平台',
    platforms_special:'平台',
    supports: '支持',
    supports_special:'支持',
    engines: '引擎',
    supports_special_engines:'支持',
    engines_special:'引擎',
    craft_perfect_gameplay: '我们提供20+种现成游戏后端服务，',
    that_why_services_intergrate: '让开发者即插即用，免去繁琐配置，快速上线游戏功能',
    allowing_developers_desc:'',
    let_create_immersive: '致力于帮助开发者在实现多人联机游戏的同时轻松创造引人入胜的游戏世界',
    platforms_engines_supports: '跨平台、多引擎全方位支持',
    // developers-box
    let_developers_focus_core: '帮助开发者专注优化',
    gameplay_experience: '游戏核心体验',
    developers_want_to_focus_desc: '使用InGame服务能够轻松为玩家构建一个丰富且沉浸式的游戏世界，',
    now_developers_can_stay_desc: '开发者只需专注于游戏核心玩法研发与打磨',
    so_what_you_waiting_desc: '您还在等什么呢？立即注册InGame让您的游戏更进一步！',
    explore_open_devportal: '探索开放 DevPortal',
    how_developers_benefit: '我们能提供：',
    cross_cloud_communication: '跨云通信',
    suppots_inter_services_communication_across: '服务间支持跨云通信、跨洲通信',
    highly_scalable_short: '高伸缩',
    supports_non_destructive: '支持无损弹性伸缩，根据业务需求动态调整资源使用，轻松提高系统的可用性及性能表现',
    fully_hosted: '全托管',
    supports_failover_services_governance: '支持故障切换、服务治理、多云部署管理及支持云原生内外混合部署，轻松支持玩家大规模增长',
    // footer
    resources: '资源',
    roadmap: '路线图',
    docs: '文档中心',
    support: '支持',
    about: '关于',
    we_are_online: '关于InGame',
    contact_for_product: '获取产品最新动态与商业合作方式',
    // lead_studios
    trusted_by_studios: '深受世界领先游戏工作室信赖',
    // statement-box
    security: '安全',
    statement: '声明',
    in_game_follows_highest_standard1: 'InGame 遵循最高的信息安全和隐私保护标准，',
    in_game_follows_highest_standard2: '致力于保护信息系统、知识产权、个人和客户数据免遭滥用或泄露',
    // 字词盒子
    // all_over_world: '遍布全球',
    global_support:'全球团队支持，',
    seamless_service : '服务无缝链接',
    we_are_committed_desc:'我们致力于提供全球支持，在英国、美国和中国设有驻点办公室。',
    we_have_service_centers_desc:'在新加坡、北美、欧洲和上海设有服务中心。',
    our_worldwide_battle_server_desc:'我们的战斗服务器覆盖全球各地，确保游戏在世界各地顺利部署与运行。',

    deployment_regions: '不限部署地域',
    deployment_regions_lowercase: '部署区域',
    master: '游戏服',
    china_china: '中国：中国 - ',
    shanghai: '上海',
    north_america_us_east: '北美：美国东部 - ',
    virginia: '弗吉尼亚州',
    all_over_world_uppercase: '遍布全球',
    europe_europe: '欧洲：欧洲 - ',
    frankfurt: '法兰克福',
    asia_pacific_southeast: '亚太地区：东南亚 - ',
    singapore: '新加坡',
    dedicated_server: '对战服',
    support_cloud_server_providers: '不限云服务商',
    amazon_web_services: '亚马逊网络服务',
    microsoft_azure: '微软云',
    tencent_cloud: '腾讯云',
    // not_found
    sorry_page_not_exist: '抱歉，您访问的页面不存在。',
    back: '返回',
    // components
    contact_us_upper: '联系我们',
    demo_test: '演示测试',
    please_sign_in_first: '请先登录',
    api_error_message: 'API 错误 {{url}}: {{statusText}} ({{status}})',
    my_studios_portal: '我的工作室门户',
    time_out: '超时',
    required_message: '{{name}} 是必填的。',
    max_characters_50: '最多 50 个字符。',

}