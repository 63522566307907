import styles from './style.module.scss';
import { BoxFrame } from '../box-frame';
import classNames from 'classnames';
interface ITem {
  readonly text?: string;
  readonly textColor?: 'primary' | 'blue' | 'orange' | 'green'| 'black';
}
interface IProps {
  readonly items?: ITem[];
  readonly itemsExtra?: ITem[];
  readonly type?: 'inline';
  readonly size?: 'small' | 'mini' | 'xmini';
  readonly align?: string;
  readonly innerClassName?: any;
  readonly className?: any;
  readonly itemsExtraSize?: 'large' | undefined;
  readonly itemsExtraMaxWidth?: number;
}

export const ItemTitle = (props: IProps) => {
  return (
    <BoxFrame
      innerClassName={classNames(
        styles.itemTitle,
        props.align === 'start' && styles.isAlignStart,
        props.className
      )}
      className={classNames(
        styles.itemTitleWrapper,
        props.align === 'start' && styles.isAlignStart,
        props.innerClassName
      )}
    >
      <h2
        className={classNames(
          styles.itemTitleText,
          props.type === 'inline' && styles.isInline,
          props.size === 'small' && styles.isSmall,
          props.size === 'mini' && styles.isMini,
          props.size === 'xmini' && styles.isXMini
        )}
      >
        {props.items?.map((item, index) => (
          <span
            key={index}
            className={classNames(
              item.textColor === 'primary' && 'color-primary',
              item.textColor === 'blue' && 'color-blue',
              item.textColor === 'orange' && 'color-orange',
              item.textColor === 'green' && 'color-green',
              item.textColor === 'black' && 'color-black',
              'text-item'
            )}
          >
            {item.text}
          </span>
        ))}
      </h2>
      {props.itemsExtra && (
        <ul
          className={classNames(
            styles.titleExtraList,
            props.itemsExtraSize === 'large' && styles.isLarge
          )}
          style={{ maxWidth: props.itemsExtraMaxWidth }}
        >
          {props.itemsExtra?.map((item, index) => (
            <li className="list-item" key={index}>
              {item.text}
            </li>
          ))}
        </ul>
      )}
    </BoxFrame>
  );
};
