export default {
    email_address: 'Email address',
    unleashing:'Unleashing',
    creativity:'Creativity, ',
    unbounded:'Unbounded',
    platforms:'Platforms',
    platforms_special:'Supported',
    supports:'supports',
    supports_special:'Platforms',
    engines:'Engines',
    supports_special_engines:'Engines',
    engines_special:'Supported',
    craft_perfect_gameplay:'We understand your focus is crafting the perfect gameplay. ',
    that_why_services_intergrate:'That\'s why we offer 20+ ready-to-use game backend services, ',
    allowing_developers_desc:'allowing developers to seamlessly integrate them without the hassle of complex configurations. ',
    let_create_immersive:'Letting you create immersive in-game worlds while implementing multiplayer online gaming experiences.',
    platforms_engines_supports:'Platforms and Engines',
    // developers-box
    let_developers_focus_core:'Let developers focus on polishing the core',
    gameplay_experience:'gameplay experience',
    developers_want_to_focus_desc:'Developers want to focus on polishing the core gameplay experience. We get it! Getting started with InGame makes it easier to add a rich and immersive in-game world for your players to explore.',
    now_developers_can_stay_desc:'Now developers can stay focused and the business can stay aligned.',
    so_what_you_waiting_desc:'So, what are you waiting for? Try InGame today and take your game to the next level!',
    explore_open_devportal:'Explore Open DevPortal',
    how_developers_benefit:'How developers are benifiting',
    cross_cloud_communication:'Cross-cloud Communication',
    suppots_inter_services_communication_across:'Supports inter-service, communication across multiple clouds and continents.',
    highly_scalable_short:'Highly Scalable',
    supports_non_destructive:'Supports non-destructive elastic scaling. It dynamically adjusts resource usage according to business needs, enhancing system availability and performance.',
    fully_hosted: 'Fully Hosted',
    supports_failover_services_governance:'Supports failover, service governance, hybrid deployments and management of multi-cloud deployments, making it easy to accommodate large-scale user growth.',
    // footer
    resources:'Resources',
    roadmap: 'Roadmap',
    docs:'Docs',
    support:'Support',
    about:'About',
    we_are_online:'We\'re Online',
    contact_for_product:'Contact for product updates, events and cooperation',
    // lead_studios
    trusted_by_studios:'Trusted by The World\'s Leading Game Studios',
    // statement-box
    security:'Security',
    statement:'Statement',
    in_game_follows_highest_standard1:'InGame follows the highest standard when it comes to information security and privacy protection, and is committed to protecting information systems, intellectual property, personal, and customer data from misuse or compromise.',
    in_game_follows_highest_standard2:'',
    // word-box
    global_support:'Global Support, ',
    seamless_service:'Seamless Service',
    // reliable_communications_technology:'Reliable communications technology',

    we_are_committed_desc:'We are committed to delivering global support with offices in the UK, USA, and China.',
    we_have_service_centers_desc:'We have service centers in Singapore, North America, Europe, and Shanghai.',
    our_worldwide_battle_server_desc:'Our worldwide battle server deployment ensures smooth game operation and seamless performance across the globe.',

    deployment_regions:'Deployment regions',
    deployment_regions_lowercase:'deployment regions',
    master:'Master',
    china_china:'China: China - ',
    shanghai:'Shanghai',
    north_america_us_east:'North America: US East - ',
    virginia:'Virginia',
    all_over_world_uppercase:'All Over The World',
    europe_europe:'Europe: Europe - ',
    frankfurt:'Frankfurt',
    asia_pacific_southeast:'Asia Pacific: Southeast Asia - ',
    singapore :'Singapore',
    dedicated_server:'Dedicated server',
    support_cloud_server_providers:'Supported cloud service providers',
    amazon_web_services:'Amazon Web Services',
    microsoft_azure:'Microsoft Azure',
    tencent_cloud:'Tencent Cloud',
    // not_found
    sorry_page_not_exist:'Sorry, the page you visited does not exist.',
    back:'Back',
    // components
    contact_us_upper:'Contact Us',
    demo_test:'Demo Test',
    please_sign_in_first:'Please sign in first',
    api_error_message: 'API error {{url}}: {{statusText}} ({{status}})',
    my_studios_portal:'My Studios Portal',
    time_out: 'TimeOut',
    required_message: '{{name}} is required.',
    max_characters_50: 'Maximum 50 characters.',

}